import axios from 'axios';
import { ROOT_URL, AZURE_STORAGE_MULTIMEDIA_CONTAINER, BLOB_ACCOUNT, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de las respuestas.

// Actualiza las respuestas pendientes del estado.
export const GET_PENDING_ANSWERS =  'GET_PENDING_ANSWERS';

// Actualiza las respuestas pendientes del estado.
export const GET_PENDING_EXAMS =  'GET_PENDING_EXAMS';

// Actualiza las respuestas cerradas del estado.
export const GET_CLOSED_EXAMS =  'GET_CLOSED_EXAMS';

// Obtiene la respuesta actual para el estado.
export const GET_ANSWER = 'GET_ANSWER';

// Remueve la respuesta actual del estado.
export const REMOVE_CURRENT_ANSWER = 'REMOVE_CURRENT_ANSWER';

// Actualiza las respuestas cerradas del estado.
export const GET_CLOSED_ANSWERS = 'GET_CLOSED_ANSWERS';

// Actualiza las respuestas cerradas del estado.
export const GET_MULTIMEDIA_URL= "GET_MULTIMEDIA_URL";

/**
 * Obtiene todas las respuestas en estado pendiente.
 *
 * @export getPendingAnswers
 * @param pagination : Opciones de paginación.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getPendingAnswers(pagination) {
  const request = axios.get(`${ROOT_URL}/answers/pending${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_PENDING_ANSWERS,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching pending answers', error);
      }
    });
  }
}

/**
 * Obtiene todas las respuestas de examenes en estado pendiente.
 *
 * @export getPendingExams
 * @param pagination : Opciones de paginación.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getPendingExams(pagination) {
  const request = axios.get(`${ROOT_URL}/exams/pending${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_PENDING_EXAMS,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching pending answers', error);
      }
    });
  }
}

/**
 * Obtiene todas las respuestas de examenes en estado terminado.
 *
 * @export getClosedExams
 * @param pagination : Opciones de paginación.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getClosedExams(pagination) {
  const request = axios.get(`${ROOT_URL}/exams/closed${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_CLOSED_EXAMS,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching pending answers', error);
      }
    });
  }
}

/**
 * Obtener la respuesta con el id dado.
 *
 * @export getAnswer
 * @param {*} id : El id de la respuesta
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAnswer(id) {
  const request = axios.get(`${ROOT_URL}/answers/${id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching the answer', error);
      }
    });
  }
}

/**
 * Dispatch de la acción para remover del state la información de la respuesta actual.
 *
 * @export removeCurrentAnswer
 * @returns Dispatch para remover la respuesta actual en el estado.
 */
export function removeCurrentAnswer() {
  return {
    type: REMOVE_CURRENT_ANSWER
  };
}

/**
 * Inicializar la respuesta con el id dado en el estado iniciada.
 *
 * @export startAnswer
 * @param {*} id : El id de la respuesta.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function startAnswer(id) {
  const request = axios.patch(
    `${ROOT_URL}/answers/${id}/start`,
    null,
    getAuthHeaders()
  );

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error starting the answer', error);
      }
    });
  }
}

/**
 * Actualizar la respuesta dada por parámetro.
 *
 * @export updateAnswer
 * @param {*} answer: El objeto respuesta con información actualizada, debe tener el atributo 'id'.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateAnswer(answer, file) {
  let body = file;

  if(file === undefined) {
    body = {answer: answer};
  }

  const request = axios.patch(
    `${ROOT_URL}/answers/${answer.id}`,
    body,
    getAuthHeaders()
  );

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error updating the answer', error);
      }
    });
  }
}

/**
 * Finalizar la respuesta con el id dado.
 *
 * @export finishAnswer
 * @param {*} answer : Información de la respuesta a finalizar.
 * @param {*} file : posible archivo adjunto de la respuesta.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function finishAnswer(answer, file, successCallback = () => {}, errorCallback = (error) => {}) {
  let request = null;
  let body = file;

  if(file === undefined) {
    body = {answer: answer};
  }

  request = axios.put(
    `${ROOT_URL}/answers/${answer.id}/finish`,
    body,
    getAuthHeaders()
    );

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER,
        payload: data
      });

      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Obtiene todas las respuesta en estado cerrado.
 *
 * @export getClosedAnswers
 * @param pagination : Opciones de paginación.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getClosedAnswers(pagination) {
  const request = axios.get(`${ROOT_URL}/answers/closed${pagination}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_CLOSED_ANSWERS,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching closed answers', error);
      }
    });
  }
}


/**
 * Obtener el enlace multimedia de la respuesta con el id dado.
 *
 * @export getMultimediaUrl
 * @param {*} id : El id de la respuesta
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getMultimediaUrl(id, ip) {
  const request = axios.get(`${ROOT_URL}/answers/${id}/multimedia_url?blob=${BLOB_ACCOUNT}&sip=${ip}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_MULTIMEDIA_URL,
        payload: data
      });
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        console.log('Error fetching the answer', error);
      }
    });
  }
}

/**
 * Envía el resultado de la carga de un archivo multimedia al blob.
 *
 * @export sendBlobUploadResult
 * @param {*} id : El id de la respuesta
 * @param {*} log_result : Resultado de la carga del archivo multimedia al blob
 * @returns Función de manejo de error o éxito de la petición.
 */
export function sendBlobUploadResult(id, upload_result) {
  axios.post(`${ROOT_URL}/answers/${id}/get_blob_upload_result`, upload_result, getAuthHeaders())
  .catch((error) => {
    console.log('log_result')
  })
}