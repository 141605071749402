import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de los departamentos.

// Actualiza todas las asignacioness en el estado de la aplicación.
export const GET_ASSIGNATIONS = 'GET_ASSIGNATIONS';

// Actualiza la asignaciones dada del estado de la aplicación.
export const GET_ASSIGNATION = 'GET_ASSIGNATION';

// Actualiza la asignaciones dada del estado de la aplicación.
export const GET_STATUS_REPORT = 'GET_STATUS_REPORT';

// Actualiza la pregunta dada del estado de la aplicación.
export const GET_ASSIGNATION_REPORT = 'GET_ASSIGNATION_REPORT';

// Crea una asignación nueva.
export const CREATE_ASSIGNATION = 'CREATE_ASSIGNATION';

// Actualiza la respuestas de la asignacion.
export const  GET_ASSIGNATION_ANSWERS = 'GET_ASSIGNATION_ANSWERS';

// Actualiza la respuesta de la asignacion.
export const  GET_ASSIGNATION_ANSWER = 'GET_ASSIGNATION_ANSWER';

// Actualiza la respuesta de la asignacion.
export const  GET_ANSWER_GRADERS = 'GET_ANSWER_GRADERS';

//anula las codificaciones de un codificador en una asignación
export const ANULL_GRADER_GRADES = 'ANULL_GRADER_GRADES'

//anula las codificaciones de una asignación
export const ANULL_ASSIGNATION_GRADES = 'ANULL_ASSIGNATION_GRADES'

//obtiene la cantidad de asignaciones de un codificador disponibles para reasignar
export const GET_GRADER_ANULLED_AVIABLE_REASIGN = 'GET_ANULLED_AVIABLE_REASIGN';

//obtiene los codificadores de una asignación
export const GET_GRADERS = 'GET_GRADERS';

//obtiene la cantidad de codificaciones no acabadas disponibles para reasignar
export const GET_ASSIGNATION_NOT_DONE_AVIABLE_REASIGN = 'GET_ASSIGNATION_NOT_DONE_AVIABLE_REASIGN';

//obtiene la cantidad de codificaciones anuladas disponibles para reasignar
export const GET_ASSIGNATION_ANULLED_AVIABLE_REASIGN = 'GET_ASSIGNATION_ANULLED_AVIABLE_REASIGN'

//reasigna las codificaciones de un codificador
export const REASSIGN_GRADERS_GRADES = 'REASSIGN_GRADERS_GRADES'

export const GET_GRADER_TODO_AVIABLE_REASIGN = 'GET_GRADER_TODO_AVIABLE_REASIGN'

export const GET_PARTIAL_COD_FROM_PARTICIPANTS ='GET_PARTIAL_COD_FROM_PARTICIPANTS'

//obtiene la estructura de los paquetes de una asignación
export const GET_PACKET_STRUCTURE = 'GET_PACKET_STRUCTURE';

//borra una asignacion basica
export const DELETE_ASSIGNATION = 'DELETE_ASSIGNATION';

/**
 * Obtiene las asignaciones de un emxa_test.
 *
 * @export getAssignations
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAssignations(exam_test_id, pagination, successCallback = () => {}, errorCallback = (error) => {}) {
  if (pagination===undefined){
    pagination = ""
  }
  const request = axios.get(`${ROOT_URL}/assignations${pagination}?&exam_test_option_id=${exam_test_id}`, getAuthHeaders()); 

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATIONS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Obtiene el detalle de la asignación con el id dado.
 *
 * @export getAssignation
 * @param {*} assignation_id : Id de la asignación
 * @param {*} exam_test_id:  Id del exam test
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAssignation(assignation_id, exam_test_option_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/assignations/${assignation_id}?exam_test_option_id=${exam_test_option_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION,
        payload: data
      });
      successCallback({data});
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }

}
/**
 * Obtiene el detalle de la asignación con el id dado.
 *
 * @export getAssignation
 * @param {*} assignation_id : Id de la asignación
 * @param {*} exam_test_id:  Id del exam test
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getStatusReport(assignation_id, exam_test_option_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/assignations/${assignation_id}/status_report?exam_test_option_id=${exam_test_option_id}`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_STATUS_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }

}
/**
 * Obtiene la informacion del estado de una asignación con el id dado.
 *
 * @export getAssignationReport
 * @param {*} exam_id : El id del examen.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getAssignationReport(id,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.get(`${ROOT_URL}/assignations/${id}/report/`, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION_REPORT,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }

}


/**
 * Crea o actualiza una asignación con los datos dados por parámetro
 *
 * @export saveAssignation
 * @param {*} exam : Información del examen a crear.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function saveAssignation(exam_test_id, participantsInfo, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exam_test_options/${exam_test_id}/save_assignation/`, participantsInfo, getAuthHeaders());
 
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: CREATE_ASSIGNATION,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Crea una asignación con los datos dados por parámetro
 *
 * @export createGroupAssignation
 * @param {*} exam : Información del examen a crear.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function createGroupAssignation(exam_test_id, participantsInfo, onlyFinished = false, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/exam_test_options/${exam_test_id}/save_group_assignation?onlyFinished=${onlyFinished}`, participantsInfo, getAuthHeaders());
 
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: CREATE_ASSIGNATION,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

  /**
 * Modificación de la capacidad y valor de codificación de respuesta con único codificador de los codificadores de la pregunta en el examen
 * con exam_test_id dado.
 *
 * @export
 * @param {*} exam_test_id : El id de la pregunta del examen.
 * @param {*} participantsCapacityAndUnique: El id del examen, los ids de los codificadores, su respectiva capacidad, y si son para respuestas de único codificador
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function editCapacityAndUniqueParticipantsExamTest(id, participantsCapacityAndUnique,  successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/assignations/${id}/edit_capacity_and_unique`, {capacities: participantsCapacityAndUnique}, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Asignación de un examen a codificadores. Se discrimina la cantidad de respuestas a codificar
 * en cada uno de los tipos de asignación: asignación para todos, asignación doble, única asignación.
 *
 * @export assignGraderExamTest
 * @param {*} id : El id de la pregunta cuyas respuestas se busca asignar codificador.
 * @param {*} exam_id : El examen cuyas respuestas se busca asignar.
 * @param {*} assign_for_all: Cantidad de respuestas a asignar a todos.
 * @param {*} double_percentage: Cantidad de respuestas a asignar a dos codificadores.
 * @param {*} unique_assign: Cantidad de respuestas a asignar a un único codificador.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function assignGraderAndReviewerAssignation(id,  assign_for_all, double_percentage, unique_assign, packetAssignationParameters, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/assignations/${id}/assign_graders_and_reviewers`,
                              {assign_for_all: assign_for_all,
                               double_percentage: double_percentage,
                               unique_assign: unique_assign,
                              packet_assignation: packetAssignationParameters },
                               getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}


/**
 * Anula las codificaciones de un codificador 
 *
 * @export anullGraderGrades
 * @param {*} id : Id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function anullGraderGrades(groups, et_id, id, user_id,successCallback = () => {}, errorCallback = (error) => {}){

  const request = axios.patch(`${ROOT_URL}/assignations/${id}/anull_grader_grades`,
                              {groups: groups,
                              exam_test_id: et_id,
                              user_id: user_id
                              },
                              getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: ANULL_GRADER_GRADES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      } 
    });
  }
}

/**
 * Anula las codificaciones de una asignacion
 *
 * @export anullAssignationGrades
 * @param {*} id : Id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function anullAssignationGrades(groups, et_id, id, user_id,successCallback = () => {}, errorCallback = (error) => {}){

  const request = axios.patch(`${ROOT_URL}/assignations/${id}/anull_assignation_grades`,
                              {groups: groups,
                              exam_test_id: et_id,
                              user_id: user_id
                              },
                              getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: ANULL_ASSIGNATION_GRADES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      } 
    });
  }
}


/**
 * obtiene la cuenta de la cantidad de codificaciones de un codificador en esta asignación que no hayan sido reasignadas previamente
 *
 * @export getGraderAnulledAviableReassign
 * @param {*} id : Id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function getGraderAnulledAviableReassign(id,user_id, exam_test_id, successCallback = () => {}, errorCallback = (error) => {}){
  
  let headers = getAuthHeaders()['headers']
  let config = {
    headers,
    params: { 
      exam_test_id: exam_test_id,
      grader_id: user_id
    }
  }
  
  const request = axios.get(`${ROOT_URL}/assignations/${id}/get_grader_anulled_aviable_reassign`,config);
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADER_ANULLED_AVIABLE_REASIGN,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * obtiene la cantidad de codificaciones en estado [on_hold to_do in_review] que tiene ese codificador en ese exam_test en esa asignación
 *
 * @export getGraderToDoAviableReassign
 * @param {*} id : Id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function getGraderToDoAviableReassign(id,user_id, exam_test_id, successCallback = () => {}, errorCallback = (error) => {}){
  
  let headers = getAuthHeaders()['headers']
  let config = {
    headers,
    params: { 
      exam_test_id: exam_test_id,
      grader_id: user_id
    }
  }
  
  const request = axios.get(`${ROOT_URL}/assignations/${id}/get_grader_not_done_aviable_reassign`,config);
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADER_TODO_AVIABLE_REASIGN,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * obtiene los codficadores asociados a la asignación
 *
 * @export getGraders
 * @param {*} id : Id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */



export function getGraders(id, exam_test_option_id, successCallback = () => {}, errorCallback = (error) => {}){
  let headers = getAuthHeaders()['headers']
  let config = {
    headers,
    params: { exam_test_option_id: exam_test_option_id }
  }

  const request = axios.get(`${ROOT_URL}/assignations/${id}/get_graders`, config);
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_GRADERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * obtiene la cantidad de codificaciones en estado [anulled] que tiene esa asignación
 *
 * @export getAssignationAnulledAviableReassign
 * @param {*} id : Id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function getAssignationAnulledAviableReassign(id, exam_test_id, successCallback = () => {}, errorCallback = (error) => {}){
  let headers = getAuthHeaders()['headers']
  let config = {
    headers,
    params: { exam_test_option_id: exam_test_id }
  }
  const request = axios.get(`${ROOT_URL}/assignations/${id}/get_assignation_anulled_aviable_reassign`,config);
  return (dispatch) => { 
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION_ANULLED_AVIABLE_REASIGN,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * obtiene la cantidad de codificaciones en estado [on_hold to_do in_review] que tiene ese codificador en esa exam_test en esa asignación
 *
 * @export getAssignationNotDoneAviableReassign
 * @param {*} id : Id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function getAssignationNotDoneAviableReassign(id, exam_test_id, successCallback = () => {}, errorCallback = (error) => {}){
  let headers = getAuthHeaders()['headers']
  let config = {
    headers,
    params: { exam_test_option_id: exam_test_id }
  }
  const request = axios.get(`${ROOT_URL}/assignations/${id}/get_assignation_not_done_aviable_reassign`,config);
  return (dispatch) => { 
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION_NOT_DONE_AVIABLE_REASIGN,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * reasigna las codificaciones y calificacion que hay  de un codificador 
 *
 * @export reasignGraderGrades
 * @param {*} id : Id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function reasignGraderGrades(values_to_reassign, id, et_id, user_id,successCallback = () => {}, errorCallback = (error) => {}){
  
  const request = axios.patch(`${ROOT_URL}/assignations/${id}/reassign_grader_grades`,
                              {
                                values_to_reassign: values_to_reassign,
                                exam_test_id: et_id,
                                grader_id: user_id
                              },
                              getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: REASSIGN_GRADERS_GRADES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      console.log(error)
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      } 
    });
  }
}


/**
 * reasigna las codificaciones y calificacion que hay  de una asignacion
 *
 * @export reasignAssignationGrades
 * @param {*} id : Id de la asignacion.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */

export function reasignAssignationGrades(values_to_reassign, id, et_id, successCallback = () => {}, errorCallback = (error) => {}){
  
  const request = axios.patch(`${ROOT_URL}/assignations/${id}/reassign_assignation_grades`,
                              {
                                values_to_reassign: values_to_reassign,
                                exam_test_id: et_id,
                              },
                              getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: REASSIGN_GRADERS_GRADES,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      console.log(error)
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      } 
    });
  }
}
/**
 * Trae la información del los participantes para la ventana de edición.
 *
 * @export getPartialCodFromParticipants
 * @param {*} id : El id la asignación a consultar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getPartialCodFromParticipants(id, successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.get(`${ROOT_URL}/assignations/${id}/get_partial_cod_from_participants`,getAuthHeaders())

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_PARTIAL_COD_FROM_PARTICIPANTS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      console.log(error)
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza la asignación con id dado por parámetro con la información en el objeto assignation dado
 *
 * @export updateAssignation
 * @param {*} id : El id del examen a actualizar.
 * @param {*} assignation : La información del examen a actualizar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function updateAssignation(id, assignation, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/assignations/${id}/update`, {assignation: assignation}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * Actualiza la asignación con id dado por parámetro con la información en el objeto assignation dado
 *
 * @export updateAssignationAnswer
 * @param {*} id : El id de la asignación a actualizar
 * @param {*} assignation : La información del examen a actualizar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function updateAssignationAnswer(id, answer, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/assignations/${id}/reassign`, {answer: answer}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Trae la información del las respuestas de la asignación para editar los participantes.
 *
 * @export getAssignationAnswers
 * @param {*} id : El id la asignación a consultar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getAssignationAnswers(id, pagination,successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.get(`${ROOT_URL}/assignations/${id}/assignation_answers${pagination}`,getAuthHeaders())

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION_ANSWERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      console.log(error)
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Trae la información del las respuestas de la asignación para editar los participantes.
 *
 * @export getAssignationAnswer
 * @param {*} id : El id la asignación a consultar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getAssignationAnswer(assignation_id, answer_id, successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.get(`${ROOT_URL}/assignations/${assignation_id}/assignation_answer?answer_grader=${answer_id}`,getAuthHeaders())

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ASSIGNATION_ANSWER,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      console.log(error)
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Trae la información del las respuestas de la asignación para editar los participantes.
 *
 * @export getAnswerGraders
 * @param {*} id : El id la asignación a consultar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getAnswerGraders(id,grader_id, pagination,successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.get(`${ROOT_URL}/assignations/${id}/get_answer_graders?grader=${grader_id}`,getAuthHeaders())

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_GRADERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      console.log(error)
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Rechazar la codificación de una asignación realizada.
 * con el id de asignación de revisión dado.
 *
 * @export rejectAnswerGraders
 * @param {*} id : El id de la asignación de revisión.
 * @param {*} params : Información de ala codificación rechazada.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                            La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function rejectAnswerGrader(id, params, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/assignations/${id}/reject_answer_grades`, params, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_ANSWER_GRADERS,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}
/**
 * Trae la información del las respuestas de la asignación para editar los participantes.
 *
 * @export getPacketStructure
 * @param {*} id : El id la asignación a consultar.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function getPacketStructure(assignation_id, successCallback = () => {}, errorCallback = (error) => {}){
  const request = axios.get(`${ROOT_URL}/assignations/${assignation_id}/packet_structure`,getAuthHeaders())

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_PACKET_STRUCTURE,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      console.log(error)
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 *
 * @export refusePacketsStructure
 * @param {*} id : El id de la asignacion a eliminar
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function refusePacketsStructure(assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/assignations/${assignation_id}/delete_packet_structure`,{}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_PACKET_STRUCTURE,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * @export refusePacketsStructure
 * @param {*} id : El id de la asignacion a aprobar
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
 export function approvePacketsStructure(assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/assignations/${assignation_id}/approve_packet_structure`,{}, getAuthHeaders());

  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: GET_PACKET_STRUCTURE,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

/**
 * @export deleteAssignation
 * @param {*} id : El id de la asignacion a aprobar
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function deleteAssignation(assignation_id, successCallback = () => {}, errorCallback = (error) => {}) {
  const request = axios.patch(`${ROOT_URL}/assignations/${assignation_id}/delete`,{}, getAuthHeaders());
  return (dispatch) => {
    request.then(({data}) => {
      dispatch({
        type: DELETE_ASSIGNATION,
        payload: data
      });
      successCallback();
    }).catch((error) => {
      if(!isUnauthorizedError(error, dispatch)) {
        errorCallback(error);
      }
    });
  }
}

