  /* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useSelector , useDispatch} from 'react-redux'
import { getApwindow } from "../../../actions/apwindowsActions"
import useGetRequest from "../../../customHooks/useGetRequest"
import usePostRequest from "../../../customHooks/usePostRequest"
import moment from 'moment'
import Select from "react-select"
import Loading from "../../Loading"
import swal from "sweetalert2"

const styles = {
  main: {
    display: 'flex', 
    minHeight: '70vh',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  box_style: {
    borderRadius: "15px",
    borderColor: "#E5E4E4",
    borderStyle: "solid",
    borderWidth: "thin",
    padding: "10px 0px",
    marginBottom: "9px"
  },
  noMargin: {
    margin: 0
  }
}

const AddStudents = (props) => {

  // id's segun la url
  const id = props.match.params.id
  const id_ventana = props.match?.params?.apwindow_id
  
  //estados
  const [selectData, setSelectData] = useState({
    value: null,
    options: [] 
  })

  // custom hooks
  const { data: studensList } = useGetRequest(`apwindows/${id_ventana}/users_available`)
  const { sendRequest:addByGroup, loading:createGroupLoading, data:createGroupData, error: createGroupError } = usePostRequest('apwindow_user_options/addGroup')

  // estados del reducer
  const apwindow = useSelector(state => state?.apwindows?.apwindow)
  
  // despachador
  const dispatch = useDispatch()

  // eventos
  const addStudents = (e) => {
    e.preventDefault()
    const data = {
      apwindow_id: id_ventana,
      students: selectData.value.map(obj=> obj.value)
    }
    addByGroup(data)

  }

  const renderError = (error) => {
    if (!error.response) return (<li className="has-error">{error.message}</li>)
    let {data} = error?.response
    const isArray = Array.isArray(data)
    if (typeof data !== 'object'  && !isArray) return (<li className="has-error">{error.message}</li>)
    if (isArray) data = data[0]
    return (<ul className="text-danger">
      {data.exception ? (
        <li key={`${data.error}_${data.status}`}>{data.exception}</li>
      ) : Object.keys(data).map((field, i) => (<React.Fragment key={`${field}_${i}`}>
        {data[field]?.map((reason, i) => (
          <li key={`${reason}_${i}`}>{reason}</li>
        ))}
      </React.Fragment>))}
    </ul>)
  }
  
  const successUpdate = () => {
    swal({
      type: 'success',
      title: `${selectData.value.length} estudiantes agregados a la ventana ${apwindow.title}`,
      showConfirmButton: false,
      timer: 1000
    }).then(()=> props.history.push(`/exams/${id}/apwindows`))
  }

  const selectAll = (e) => {
    e.preventDefault()
    setSelectData({...selectData, value: selectData.options})
  }

  // efectos
  useEffect(() => {
    dispatch(getApwindow(id_ventana,()=>{}, ()=> {}))
  },[])

  useEffect(() => {
    if (studensList) {
      setSelectData({...selectData, options: studensList.map(student => ({label: student.email, value: student.id}))})
    }
  },[studensList])

  // renders
  if (createGroupLoading) return (<Loading />)
  if (createGroupData) return <>{successUpdate()}</>
	return (<form onSubmit={addStudents}>
    <main style={styles.main}>
        <section>
          <h1>Añadir estudiantes</h1>
          <br />
          <div style={{display: 'flex', flexFlow: 'row wrap', alignItems: 'end', justifyContent: 'space-between'}}>
            <div>
              <p style={styles.noMargin}>Examen:</p>
              <h2 style={styles.noMargin}>{apwindow?.exam?.title}</h2>
            </div>
            <div style={{minWidth: '325px'}}>
              <p style={styles.noMargin}>
                <b>Comienza:</b> {moment(apwindow?.exam?.initialDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
              </p>
              <p style={styles.noMargin}>            
                <b>Finaliza:</b> {moment(apwindow?.exam?.finalDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
              </p>
            </div>
          </div>
          <br/>
          <div style={{display: 'flex', flexFlow: 'row wrap', alignItems: 'end', justifyContent: 'space-between'}}>
            <div>
              <p style={styles.noMargin}>Ventana:</p>
              <h2 style={styles.noMargin}>{apwindow?.title}</h2>
            </div>
            <div style={{minWidth: '325px'}}>
              <p style={styles.noMargin}>
                <b>Comienza:</b> {moment(apwindow?.startDate).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
              </p>
              <p style={styles.noMargin}>            
                <b>Finaliza:</b> {moment(apwindow?.deadline).format('D [de] MMMM [de] YYYY [a las] h:mm a')}
              </p>
            </div>
          </div>
          <br />
          <div style={{padding: '20px', display: "flex", flexFlow: "row wrap", justifyContent: 'space-between', alignItems: 'end'}}>
            <div style={{flexGrow: 1, marginRight: '1em'}}>
              <label>Estudiante</label>
              <Select
                onChange = {value => setSelectData({ ...selectData, value,})}
                value={selectData.value}
                multi={true}
                options={selectData.options}
                isClearable
                required
                placeholder='selecciona una ventana...'
              />
            </div>
            <button
              onClick={selectAll}
              className="btn btn-info" title="seleccionar todos">
              Seleccionar todos
            </button>
          </div>
          <div style={{margin: '2rem'}}>
            {(createGroupError) && renderError(createGroupError)}
          </div>
        </section>			
        <section 
          style={{
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
          }}
        >
          <button
            onClick={() => props.history.push(`/exams/${id}/apwindows`)}
            type="button"
            className="btn btn-danger">
            Volver
          </button>
          <button
            type="submit"
            className="btn btn-info">
            Guardar estudiantes
          </button>
        </section>
    </main>
  </form>)
}

export default AddStudents