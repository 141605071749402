import _ from 'lodash';
import { GET_EXAMS, GET_EXAM, DELETE_EXAM, GET_SEMESTERS, SEND_EMAILS } from '../actions/examsActions';

/**
 * Manejo de la parte del estado correspondiente a examenes.
 * Permite la actualización de examenes, obtener un examen por id.
 * Permite eliminar un examen por id.
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas de examen)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
  switch (action.type) {
    case GET_EXAMS:
      const exams = _.mapKeys(action.payload.exams, 'id');
      const pagination = action.payload.links;
      return {...state, exams: exams, pagination: pagination};
    case GET_EXAM:
      const exam = action.payload;
      return { ...state, [exam.id]: exam };
    case DELETE_EXAM:
      return {...state, exams: _.omit(state.exams, action.payload)};
    case GET_SEMESTERS:
      return {...state, semesters: action.payload.semesters};
    case SEND_EMAILS:
      return {...state};
    default:
      return state;
  }
}
