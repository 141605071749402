import React, { useEffect, useState } from "react"
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import { optionsToValues } from '../../utils';
import { FORBIDDEN_STATUS_CODE } from '../../actions/constants';
import { getExamTest, hasAnswersAviable } from '../../actions/examTestsActions';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import ParticipantsForm from '../participants/ParticipantsForm';
import { saveAssignation, getAssignation } from '../../actions/assignationsTestActions';
import Select from 'react-select';

const AssignationFormEdit = ({ match = {}, getExamTest,  hasAnswersAviable, getAssignation, saveAssignation, examTest, history })=> {
  const {id, a_id:assignationId} = match.params;

  const [state, setState] = useState({loading: false})
  const [form, setForm] = useState({
    graders: [],
    reviewers: [],
    description: [],
    allAnswers: false
  })

  const handleChange = (values, key) => {
    setForm({
      ...form,
      [key]: values
    })
    console.log('values: ', values);

  }

  const handleSubmit = (e) => {
    e.preventDefault()
    swal({
      title: '¿Estás seguro?',
      text: 'Los  codificadores y revisores serán actualizados en esta asignación',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, enviar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if(result.value) {
        const successCallback = () => {
          swal({
            type: 'success',
            title: 'Los participantes de la asignación se modificaron exitósamente.',
            showConfirmButton: false,
            timer: 1800
          });
          history.goBack()
        };
        const errorCallback = (error) => {
          console.log('Error editing the participants of the test', error.request);
          if(error.request.status === FORBIDDEN_STATUS_CODE) {
            swal(
              'Ups...',
              `Ocurrió un error al tratar de cambiar los participantes: ${JSON.parse(error.request.response).error}`,
              'error'
            );
          } else {
            swal(
              'Ups...',
              'Ocurrió un error al tratar de cambiar los participantes, asegurese que seleccionó al menos dos codificadores',
              'error'
            );
          }
        }
        const values = {
          ...form,
          graders_ids: form.graders.map(grader => (grader.value)) || [],
          reviewers_ids: form.reviewers.map(reviewer => (reviewer.value)) || [],
          all_answers: form.allAnswers,
          assignationId
        }
        saveAssignation(id, values, successCallback, errorCallback); 
      }
    });
  }

  useEffect(()=>{
    setState({loading: true})
    console.log(id, assignationId)
    getExamTest(id)
    hasAnswersAviable(id, setState({loading: false}))
    getAssignation(assignationId, id, ({data}) => {
      console.log('data: ', data);
      console.log('data: ',data.graders.map(grader => ({ value: grader.id, label: grader.email})));
      console.log('data: ', data.reviewers);

       setForm({
        graders: data.graders ? data.graders.map(grader => ({ value: grader.id, label: grader.email})) : [],
        reviewers: data.reviewers ? data.reviewers.map(reviewer => ({ value: reviewer.id, label: reviewer.email})) : [],
        description: data.description,
        allAnswers: data.allAnswers
       })
    })
  },[])
  useEffect(()=>{
    console.log('form: ', form);
  },[form])

  return(<>
    {state.loading ? (<Loading />) : (
      <>
        <div className="col-md-12 col-xs-12">
          <h1>Editar asignación</h1>
          <h2>Editar participantes</h2>
          <p> A continuación elija los codificadores que evaluarán las respuestas de esta pregunta en esta asignación, y si desea o no asignar revisores. </p>
          <p>Enfrente del correo de cada codificador y revisor puede ver entre paréntesis el nivel al cual pertenece.</p>
          <h3>Nuevos participantes</h3>
            
          <>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-xs-12">
                    <h4>Codificadores</h4>
                    <Select
                      onChange={(options)=> handleChange(options, 'graders')}
                      value={form.graders}
                      placeholder={'Codificadores'}
                      multi={true}
                      options={
                        examTest?.gradingMatrix?.graders.map(grader => {
                          return {   value: grader.id,
                            label: grader.email}
                        })
                      }
                      required={true}
                    />
                </div>
                <div className="col-md-6 col-xs-12">
                  <h4>Revisores</h4>
                  <Select
                    onChange={(options)=> handleChange(options, 'reviewers')}
                    value={form.reviewers}
                    placeholder={'Revisores'}
                    multi={true}
                    options={
                      examTest?.gradingMatrix?.reviewers.map(reviewer => {
                        return {   value: reviewer.id,
                          label: reviewer.email}
                      })
                    }
                  />
                </div>
                <div className="col-md-12">
                  <h3>Descripción de la asignación</h3>
                  <p>A continuación puede ingresar un texto para describir la asignación que se va a crear</p>                    
                </div>
                <div className="col-md-12">
                  <textarea
                    value={form.description}
                    onChange={(e)=> handleChange(e.target.value, 'description')}
                    rows="10"
                    required
                    placeholder="Descripción de la asignación"
                    className="form-control"
                  />
                </div>
                <div className="col-md-12">
                  Marque esta casilla si desea que se incluyan respuestas no finalizadas.
                  <input
                    type ="checkbox"
                    onChange={()=> handleChange(!form.allAnswers, 'allAnswers')}
                    checked={form.allAnswers}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary pull-right">
                  Guardar
                </button>
              </div>
            </form>
          </>
          <div className="col-md-6 col-xs-6 row ">
            <button
              className="btn btn-danger "
              onClick={history.goBack}>
              Volver
            </button>
          </div>
        </div>
      </>
    )}
  </>)
}

function mapStateToProps({examTests, assignations}, ownProps) {
  let examTest = examTests[ownProps.match.params.id]
  let has_answer = examTests.has_answer
  let pending_has_answers = examTests.pending_has_answers
  let {assignation} = assignations
  return { examTest, has_answer, pending_has_answers, assignation }
}

export default connect(mapStateToProps, { getExamTest, saveAssignation, hasAnswersAviable, getAssignation })(AssignationFormEdit)
