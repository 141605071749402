import {  GET_DISCREPANCY_CRIT_COD_REPORTS,GET_DISCREPANCY_COD_REPORTS ,
        GET_DISCREPANCY_CRIT_REPORTS,GET_CODIFICATION_PROGRESS_REPORTS, 
        GET_CODIFICATION_STATE_REPORTS ,GET_ANSWERS_PROGRESS_REPORTS, 
        GET_COURSE_REPORTS, GET_AGREGATE_REPORTS,GET_ACEPTED_REPORT, 
        GET_CODIFICATION_COMMENT_REPORTS, GET_CODIFICATION_COMMENT_BANK_REPORTS, GET_REVIEWERS_PROGRESS_REPORTS,
        GET_EXAM_PROGRESS_REPORTS, GET_REVIEW_PROGRESS_SUMMARY_REPORTS, 
        GET_MC_ANSWERS_PROGRESS_REPORTS, GET_DYNAMIC_REPORT, GET_ANSWER_BANK_REPORTS,
        GET_MC_ANSWERS_STUDENT_SUCCESS_REPORTS,
        GET_MC_ANSWERS_DISTRIBUTION_REPORTS, GET_STUDENT_HISTORIC_REPORTS, GET_COURSE_HISTORIC_REPORTS, GET_EXAM_TEST_REPORTS } from '../actions/reportsActions';

/**
 * Manejo de la parte del estado correspondiente a reportes
 *
 * @export
 * @param {*} [state={}] : estado de la aplicación
 * @param {*} action : operación a realizar sobre el estado (correspondiente a las preguntas de examen)
 * @returns El estado con la modificación/acción realizada.
 */
export default function (state = {}, action) {
  switch(action.type) {
    case GET_DISCREPANCY_CRIT_COD_REPORTS:
        const discrepancyCritCod = action.payload;
        const discrepancyCritCodPagination = action.payload.links;
        return { ...state, discrepancyCritCod: discrepancyCritCod, discrepancyCritCodPagination:discrepancyCritCodPagination};
    case GET_DISCREPANCY_COD_REPORTS:
        const discrepancyCod = action.payload;
        const discrepancyCodPagination = action.payload.links;
        return { ...state, discrepancyCod: discrepancyCod, discrepancyCodPagination:discrepancyCodPagination};
    case GET_DISCREPANCY_CRIT_REPORTS:
        const discrepancyCrit = action.payload;
        const discrepancyCritPagination = action.payload.links;
        return { ...state, discrepancyCrit: discrepancyCrit, discrepancyCritPagination:discrepancyCritPagination};
    case GET_CODIFICATION_PROGRESS_REPORTS:
        const codificationProgress = action.payload;
        const codificationProgressPagination = action.payload.links;
        return { ...state, codificationProgress: codificationProgress, codificationProgressPagination:codificationProgressPagination};
    case GET_CODIFICATION_STATE_REPORTS:
        const codificationState = action.payload;
        const codificationStatePagination = action.payload.links;
        return { ...state, codificationState: codificationState, codificationStatePagination: codificationStatePagination};
    case GET_CODIFICATION_COMMENT_REPORTS:
        const codificationComment = action.payload;
        const codificationCommentPagination = action.payload.links;
        return { ...state, codificationComment: codificationComment, codificationCommentPagination:codificationCommentPagination};
    case GET_CODIFICATION_COMMENT_BANK_REPORTS:
        const codificationCommentBank = action.payload;
        const codificationCommentBankPagination = action.payload.links;
        return { ...state, codificationCommentBank, codificationCommentBankPagination,};
    case GET_REVIEWERS_PROGRESS_REPORTS:
        const reviewersProgress = action.payload;
        const reviewersProgressPagination = action.payload.links;
        return { ...state, reviewersProgress: reviewersProgress, reviewersProgressPagination:reviewersProgressPagination};
    case GET_REVIEW_PROGRESS_SUMMARY_REPORTS:
        const reviewProgressSummary = action.payload;
        const reviewProgressSummaryPagination = action.payload.links;
        return { ...state, reviewProgressSummary: reviewProgressSummary, reviewProgressSummaryPagination:reviewProgressSummaryPagination};
    case GET_ANSWERS_PROGRESS_REPORTS:
        const answersProgress = action.payload;
        const answersProgressPagination = action.payload.links;
        return { ...state, answersProgress: answersProgress, answersProgressPagination:answersProgressPagination};
    case GET_MC_ANSWERS_PROGRESS_REPORTS:
        const mcAnswersProgress = action.payload;
        const mcAnswersProgressPagination = action.payload.links;
        return { ...state, mcAnswersProgress: mcAnswersProgress, mcAnswersProgressPagination:mcAnswersProgressPagination};
    case GET_MC_ANSWERS_STUDENT_SUCCESS_REPORTS:
        const mcAnswersStudentSuccess = action.payload;
        const mcAnswersStudentSuccessPagination = action.payload.links;
        return { ...state, mcAnswersStudentSuccess: mcAnswersStudentSuccess, mcAnswersStudentSuccessPagination:mcAnswersStudentSuccessPagination};
    case GET_MC_ANSWERS_DISTRIBUTION_REPORTS:
        const mcAnswersDistribution = action.payload;
        const mcAnswersDistributionPagination = action.payload.links;
        return { ...state, mcAnswersDistribution: mcAnswersDistribution, mcAnswersDistributionPagination:mcAnswersDistributionPagination};
    case GET_EXAM_PROGRESS_REPORTS:
        const examProgress = action.payload;
        const examProgressPagination = action.payload.links;
        return { ...state, examProgress: examProgress, examProgressPagination: examProgressPagination};
    case  GET_COURSE_REPORTS:
        const coursesReport =  action.payload;
        const coursesReportPagination = action.payload.links;
        return { ...state, coursesReport: coursesReport, coursesReportPagination: coursesReportPagination};
    case  GET_AGREGATE_REPORTS:
        const agregateReport = action.payload;
        const agregateReportPagination = action.payload.links;
        return { ...state, agregateReport: agregateReport, agregateReportPagination:agregateReportPagination};
    case  GET_ACEPTED_REPORT:
        const aceptedReport = action.payload;
        return { ...state, aceptedReport: aceptedReport};
    case  GET_DYNAMIC_REPORT:
        const dynamicReport = action.payload;
        return { ...state, dynamicReport: dynamicReport};
    case GET_ANSWER_BANK_REPORTS:
        const answerBankCods = action.payload;
        const answerBankCodsPagination = action.payload.links;
        return { ...state, answerBankCods: answerBankCods, answerBankCodsPagination:answerBankCodsPagination};
    case GET_STUDENT_HISTORIC_REPORTS:
        const studentHistoric = action.payload;
        const studentHistoricPagination = action.payload.links;
        return { ...state, studentHistoric: studentHistoric, studentHistoricPagination:studentHistoricPagination};
    case GET_COURSE_HISTORIC_REPORTS:
        const courseHistoric = action.payload;
        const courseHistoricPagination = action.payload.links;
        return { ...state, courseHistoric: courseHistoric, courseHistoricPagination:courseHistoricPagination};
    case GET_EXAM_TEST_REPORTS:
        const examTest = action.payload;
        const examTestPagination = action.payload.links;
        return { ...state, examTest, examTestPagination};
    default:
      return state;
  }
}
