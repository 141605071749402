import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import Loadable from 'react-loadable';
import Loading from './Loading';

//componentes
import AddByAttachment from './exams/Apwindows/AddByAttachment';
import AddStudents from './exams/Apwindows/AddStudents';
import ApwindowForm from './exams/Apwindows/ApwindowForm';
import Apwindows from './exams/Apwindows/Apwindows';
import AssignationFormEdit from './assignations/AssignationFormEdit';
import CreateProccess from './processes/CreateProccess';
import Downloads from './labelAnswers/Downloads';
import EditProcess from './processes/EditProcess';
import ExamGrades from './exams/ExamGrades/ExamGrades';
import ExamReport from './exams/ExamReport/ExamReport';
import Footer from './Footer';
import General from './labeling/Geneal';
import Home from './Home';
import LabelForm from './label/LabelForm';
import Labels from './label/Labels';
import LogIn from './authentication/LogIn';
import LogOut from './authentication/LogOut';
import NavbarNew from './NavbarNew';
import notRequireUser from './hocs/notRequireUser';
import Permissions from './processes/permissions/Permissions';
import ProcessesList from './processes/processesList';
import requireAdmin from './hocs/requireAdmin';
import requireGrader from './hocs/requireGrader';
import requireObserver from './hocs/requireObserver';
import requireObserverAdmin from './hocs/requireObserverAdmin';
import requireReviewer from './hocs/requireReviewer';
import requireReviewerGrader from './hocs/requireReviewerGrader';
import requireStudent from './hocs/requireStudent';
import requireTeacher from './hocs/requireTeacher';
import requireUser from './hocs/requireUser';
import reportMockups from './utils/reportMockups';
import StudenHistoricReport from './reports/StudentHistoricReport';
import ThemeForm from './themes/ThemeForm';
import Themes from './themes/Themes';
import ToAnswer from './exams/toAnswer/ToAnswer';
import ViewProcess from './processes/ViewProcess';
import Version from './Version';

// Imports dinámicos
const Answers = Loadable({
  loader: () => import('./answers/Answers'),
  loading: Loading,
});

const Answer = Loadable({
  loader: () => import('./answers/Answer'),
  loading: Loading,
});

const McAnswer = Loadable({
  loader: () => import('./mcAnswers/McAnswer'),
  loading: Loading,
});
const AnswerGraders = Loadable({
  loader: () => import('./answerGraders/AnswerGraders'),
  loading: Loading
});
const AnswerGrader = Loadable({
  loader: () => import('./answerGraders/AnswerGrader'),
  loading: Loading,
});
const AnswerReviewers = Loadable({
  loader: () => import('./answerReviewers/AnswerReviewers'),
  loading: Loading,
});
const AnswerReviewer = Loadable({
  loader: () => import('./answerReviewers/AnswerReviewer'),
  loading: Loading,
});
const AnswerReviewerGrade = Loadable({
  loader: () => import('./answerReviewers/AnswerReviewerGrade'),
  loading: Loading,
});
const Departments = Loadable({
  loader: () => import('./departments/Departments'),
  loading: Loading,
});
const DepartmentSemesters = Loadable({
  loader: () => import('./departments/DepartmentSemesters'),
  loading: Loading,
});
const DepartmentExams = Loadable({
  loader: () => import('./departments/DepartmentExams'),
  loading: Loading,
});
const DepartmentReport = Loadable({
  loader: () => import('./departments/DepartmentReport'),
  loading: Loading,
});
const Faculties = Loadable({
  loader: () => import('./faculties/Faculties'),
  loading: Loading,
});
const FacultySemesters = Loadable({
  loader: () => import('./faculties/FacultySemesters'),
  loading: Loading,
});
const FacultyExams = Loadable({
  loader: () => import('./faculties/FacultyExams'),
  loading: Loading,
});
const FacultyReport = Loadable({
  loader: () => import('./faculties/FacultyReport'),
  loading: Loading,
});
const Exams = Loadable({
  loader: () => import('./exams/Exams'),
  loading: Loading,
});
const Exam = Loadable({
  loader: () => import('./exams/Exam'),
  loading: Loading,
});
const ExamNew = Loadable({
  loader: () => import('./exams/ExamNew'),
  loading: Loading,
});
const ExamEdit = Loadable({
  loader: () => import('./exams/ExamEdit'),
  loading: Loading,
});
const ExamQuestionaries = Loadable({
  loader: () => import('./exams/ExamQuestionaries'),
  loading: Loading,
});
const ExamProgress = Loadable({
  loader: () => import('./exams/ExamProgress'),
  loading: Loading,
});
const ExamEditParticipants = Loadable({
  loader: () => import('./exams/ExamEditParticipants'),
  loading: Loading,
});
const ExamList = Loadable({
  loader: () => import('./exams/ExamList'),
  loading: Loading,
});
const Notification = Loadable({
  loader: () => import('./notifications/Notification'),
  loading: Loading,
});
const Questionaries = Loadable({
  loader: () => import('./questionaries/Questionaries'),
  loading: Loading,
});
const QuestionaryDetail = Loadable({
  loader: () => import('./questionaries/QuestionaryDetail'),
  loading: Loading,
});
const QuestionaryPreviousView = Loadable({
  loader: () => import('./questionaries/QuestionaryPreviousView'),
  loading: Loading,
});
const QuestionaryAsociatePreview = Loadable({
  loader: () => import('./questionaries/QuestionaryAsociatePreview'),
  loading: Loading,
});
const QuestionaryEdit = Loadable({
  loader: () => import('./questionaries/QuestionaryEdit'),
  loading: Loading,
});
const QuestionaryNew = Loadable({
  loader: () => import('./questionaries/QuestionaryNew'),
  loading: Loading,
});

const Tests = Loadable({
  loader: () => import('./tests/Tests'),
  loading: Loading,
});
const Test = Loadable({
  loader: () => import('./tests/Test'),
  loading: Loading,
});
const TestNew = Loadable({
  loader: () => import('./tests/TestNew'),
  loading: Loading,
});
const TestEdit = Loadable({
  loader: () => import('./tests/TestEdit'),
  loading: Loading,
});
const ExamTests = Loadable({
  loader: () => import('./exam_tests/ExamTests'),
  loading: Loading,
});
const Courses = Loadable({
  loader: () => import('./courses/Courses'),
  loading: Loading,
});
const CoursesAdmin = Loadable({
  loader: () => import('./courses/CoursesAdmin'),
  loading: Loading,
});
const SemesterAddYear = Loadable({
  loader: () => import('./semesterYear/SemesterAddYear'),
  loading: Loading,
});
const SemesterCreate = Loadable({
  loader: () => import('./semesterYear/SemesterCreate'),
  loading: Loading,
});
const CoursesAddTeachers = Loadable({
  loader: () => import('./courses/CoursesAddTeachers'),
  loading: Loading,
});
const CoursesAddStudents = Loadable({
  loader: () => import('./courses/CoursesAddStudents'),
  loading: Loading,
});
const CoursesTeacher = Loadable({
  loader: () => import('./courses/CoursesTeacher'),
  loading: Loading,
});
const CourseExams = Loadable({
  loader: () => import('./courses/CourseExams'),
  loading: Loading,
});
const CourseHistoricExams = Loadable({
  loader: () => import('./courses/CourseHistoricExams'),
  loading: Loading,
});
const CourseSemesters = Loadable({
  loader: () => import('./courses/CourseSemesters'),
  loading: Loading,
});
const CourseReport = Loadable({
  loader: () => import('./courses/CourseReport'),
  loading: Loading,
});
const Assignations = Loadable({
  loader: () => import('./assignations/AssignationsTest'),
  loading: Loading,
});
const AssignationNew = Loadable({
  loader: () => import('./assignations/AssignationNew'),
  loading: Loading,
});
const AssignationParticipants = Loadable({
  loader: () => import('./assignations/AssignationAssignParticipants'),
  loading: Loading,
});
const AssignationGroupAssign = Loadable({
  loader: () => import('./assignations/AssignationGroupAssign'),
  loading: Loading,
});
const AssignationDetail = Loadable({
  loader: () => import('./assignations/AssignationDetail'),
  loading: Loading,
});
const AssignationEdit = Loadable({
  loader: () => import('./assignations/AssignationEdit'),
  loading: Loading,
});
const AssignationReassign = Loadable({
  loader: () => import('./assignations/AssignationReassign'),
  loading: Loading,
});
const AssignationAnswers = Loadable({
  loader: () => import('./assignations/AssignationAnswers'),
  loading: Loading,
});
const AssignationParticipantsCapacity = Loadable({
  loader: () => import('./assignations/AssignationParticipantsCapacity'),
  loading: Loading,
});
const AssignationReport = Loadable({
  loader: () => import('./assignations/AssignationReport'),
  loading: Loading,
});
const AssignationGrader = Loadable({
  loader: () => import('./assignations/AssignationGrader'),
  loading: Loading,
});
const AssignationGraderCodifications = Loadable({
  loader: () => import('./assignations/AssignationGraderCodifications'),
  loading: Loading,
});

const AssignationAnullReasign = Loadable({
  loader: () => import('./assignations/AssignationAnullReasign'),
  loading: Loading,
});

const AssignationAcceptPacketStructure = Loadable({
  loader: () => import('./assignations/AcceptPacketStructure'),
  loading: Loading,
});

const ExamTestsCourses = Loadable({
  loader: () => import('./exam_tests/ExamTestsCourses'),
  loading: Loading,
});
const ExamTestsAnswers = Loadable({
  loader: () => import('./exam_tests/ExamTestsAnswers'),
  loading: Loading,
});
const ExamQuestionariesAnswers = Loadable({
  loader: () => import('./exam_questionaries/ExamQuestionariesAnswers'),
  loading: Loading,
});
const ExamQuestionariesReport = Loadable({
  loader: () => import('./exam_questionaries/ExamQuestionariesReport'),
  loading: Loading,
});
const Texts = Loadable({
  loader: () => import('./texts/Texts'),
  loading: Loading,
});
const TextNew = Loadable({
  loader: () => import('./texts/TextNew'),
  loading: Loading,
});
const Users = Loadable({
  loader: () => import('./users/Users'),
  loading: Loading,
});
const UserNew = Loadable({
  loader: () => import('./users/UserNew'),
  loading: Loading,
});
const UserEditRoles = Loadable({
  loader: () => import('./users/UserEditRoles'),
  loading: Loading,
})
const GradingMatrices = Loadable({
  loader: () => import('./gradingMatrices/GradingMatrices'),
  loading: Loading,
});
const GradingMatrixNew = Loadable({
  loader: () => import('./gradingMatrices/GradingMatrixNew'),
  loading: Loading,
});
const GradingMatrix = Loadable({
  loader: () => import('./gradingMatrices/GradingMatrix'),
  loading: Loading,
});
const GradingMatrixEdit = Loadable({
  loader: () => import('./gradingMatrices/GradingMatrixEdit'),
  loading: Loading,
});
const GradingMatrixEditParticipants = Loadable({
  loader: () => import('./gradingMatrices/GradingMatrixEditParticipants'),
  loading: Loading,
});
const AnswerGraderOverwrite = Loadable({
  loader: () => import('./answerGraders/AnswerGraderOverwrite'),
  loading: Loading,
});
const GradingMatrixEditParams = Loadable({
  loader: () => import('./gradingMatrices/GradingMatrixEditParams'),
  loading: Loading,
});
const GradingMatrixLevels = Loadable({
  loader: () => import('./gradingMatrices/GradingMatrixLevels'),
  loading: Loading,
});
const GradingMatrixEditLevels = Loadable({
  loader: () => import('./gradingMatrices/GradingMatrixEditLevels'),
  loading: Loading,
});
const GradingMatrixAssociateSuperMatrix = Loadable({
  loader: () => import('./gradingMatrices/GradingMatrixAssociateSuperMatrix'),
  loading: Loading,
});
const UserGradingMatrix = Loadable({
 loader: () => import('./usersGradingMatrices/UserGradingMatrix'),
 loading: Loading,
});
const SuperMatrices = Loadable({
  loader: () => import('./superMatrices/SuperMatrices'),
  loading: Loading,
});
const SuperMatrixNew = Loadable({
  loader: () => import('./superMatrices/SuperMatrixNew'),
  loading: Loading,
});
const SuperMatrix = Loadable({
  loader: () => import('./superMatrices/SuperMatrix'),
  loading: Loading,
});
const SuperMatrixEdit = Loadable({
  loader: () => import('./superMatrices/SuperMatrixEdit'),
  loading: Loading,
});
const SuperMatrixStudents = Loadable({
  loader: () => import('./superMatrices/SuperMatrixStudents'),
  loading: Loading,
});
const AnswerBank = Loadable({
  loader: () => import('./answerBank/AnswerBank'),
  loading: Loading,
});
const AnswerFromBank = Loadable({
  loader: () => import('./answerBank/AnswerFromBank'),
  loading: Loading,
});
const AddAnswerBankMatricesList = Loadable({
  loader: () => import('./answerBank/AddAnswerBankMatricesList'),
  loading: Loading,
});
const AddAnswerBank = Loadable({
  loader: () => import('./answerBank/AddAnswerBank'),
  loading: Loading,
});
const AddAnswerBankFromExcel = Loadable({
  loader: () => import('./answerBank/AddAnswerBankFromExcel'),
  loading: Loading,
});
const StudentHistoricReport = Loadable({
  loader: () => import('./reports/StudentHistoricReport'),
  loading: Loading,
});

const ReportMockups = Loadable({
  loader: () => import('./utils/reportMockups'),
  loading: Loading,
});

const Students = Loadable({
  loader: () => import('./students/StudentsAdmin'),
  loading: Loading,
});

const StudentsInfo = Loadable({
  loader: () => import('./students/StudentsInfo'),
  loading: Loading,
});

const NewVideoForm = Loadable({
  loader: () => import('./videos/NewVideo'),
  loading: Loading,
});

const ViewVideo = Loadable({
  loader: () => import('./videos/ViewVideo'),
  loading: Loading,
});

const UsersVideos = Loadable({
  loader: () => import('./usersVideos/UsersVideos'),
  loading: Loading,
});

const UsersVideosNew = Loadable({
  loader: () => import('./usersVideos/UsersVideosNew'),
  loading: Loading,
});

const VideoList = Loadable({
  loader: () => import('./videos/VideoList'),
  loading: Loading,
});

const EditVideo = Loadable({
  loader: () => import('./videos/EditVideo'),
  loading: Loading,
});

/**
 * Componente raiz de la aplicación. Permite configurar la ruta de la aplicación.
 *
 * @class App
 * @extends {Component}
 */
class App extends Component {
  constructor(props) {
    super(props);
    moment.locale('es');
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <NavbarNew />
          <div className="container" style={{'min-height': 'calc(100vh - 190px)'}}>
            <Switch>
            Apwindow
              <Route path="/answers/:id" component={requireStudent(Answer)} />
              <Route path="/answers" component={requireStudent(Answers)} />
              <Route path="/mcAnswers/:id" component={requireStudent(McAnswer)} />
              <Route path="/answerGraders/:id/overwrite" component={requireReviewer(AnswerGraderOverwrite)} />
              <Route path="/answerGraders/:id/:is_bank_answer" component={requireReviewerGrader(AnswerGrader)} />
              <Route path="/answerGraders/:id" component={requireReviewerGrader(AnswerGrader)} />
              <Route path="/answerGraders" component={requireGrader(AnswerGraders)} />
              <Route path="/answerReviewers/:id/grade" component={requireReviewer(AnswerReviewerGrade)} />
              <Route path="/answerReviewers/:id" component={requireReviewer(AnswerReviewer)} />
              <Route path="/answerReviewers" component={requireReviewer(AnswerReviewers)} />
              <Route path="/tests/new" component={requireAdmin(TestNew)} />
              <Route path="/tests/:id/edit" component={requireAdmin(TestEdit)} />
              <Route path="/tests/:id" component={requireAdmin(Test)} />
              <Route path="/tests" component={requireAdmin(Tests)} />
              <Route path="/questionaries/new" component={requireAdmin(QuestionaryNew)} />
              <Route path="/questionaries/:id" component={requireAdmin(QuestionaryDetail)} />
              <Route path="/questionaryAsociatePreview/:id" component={requireAdmin(QuestionaryAsociatePreview)}/>
              <Route path="/questionaryEdit/:id" component={requireAdmin(QuestionaryEdit)} />
              <Route path="/questionaries" component={requireAdmin(Questionaries)} />
              <Route path="/questionariesPreview/:id" component={notRequireUser(QuestionaryPreviousView)}/>
              <Route path="/exam-questionaries/:id/mc_answer_progress_report" component={requireAdmin(ExamQuestionariesAnswers)} />
              <Route path="/exam-questionaries/:id/report" component={requireAdmin(ExamQuestionariesReport)} />
              <Route path="/exam-tests/:id/answer_report" component={requireAdmin(ExamTestsAnswers)} />
              <Route path="/exam-tests/:id/report" component={requireAdmin(AssignationReport)} />
              <Route path="/exam-tests/:id/examTestReport" component={requireAdmin(ExamReport)} />
              <Route path="/exam-tests/:id/courses" component={requireAdmin(ExamTestsCourses)} />
              <Route path="/exam-tests/:id/assignations/groupAssign" component={requireAdmin(AssignationGroupAssign)} />
              <Route path="/exam-tests/:id/assignations/new" component={requireAdmin(AssignationNew)} />
              <Route path="/exam-tests/:id/assignations/:a_id/edit" component={requireAdmin(AssignationFormEdit)} />
              <Route path="/exam-tests/:et_id/assignations/:a_id/assignParticipants" component={requireAdmin(AssignationParticipants)} />
              <Route path="/exam-tests/:et_id/assignations/:a_id/editAssignation" component={requireAdmin(AssignationEdit)} />
              <Route path="/exam-tests/:et_id/assignations/:a_id/editParticipantsCapacity" component={requireAdmin(AssignationParticipantsCapacity)} />
              <Route path="/exam-tests/:et_id/assignations/:a_id/answerReassign/:ans_id" component={requireAdmin(AssignationReassign)} />
              <Route path="/exam-tests/:et_id/assignations/:a_id/assignationAnswers" component={requireAdmin(AssignationAnswers)} />
              <Route path="/exam-tests/:et_id/assignations/:a_id/grader/:g_id/codifications" component={requireAdmin(AssignationGraderCodifications)} />
              <Route path="/exam-tests/:et_id/assignations/:a_id/grader/:g_id" component={requireAdmin(AssignationGrader)} />
              <Route path="/exam-tests/:et_id/assignations/:a_id/report" component={requireAdmin(AssignationReport)} />
              <Route path="/exam-tests/:et_id/assignations/:a_id/reassign" component={requireAdmin(AssignationAnullReasign)}/>
              <Route path="/exam-tests/:et_id/assignations/:a_id/acceptPacketStructure" component={requireAdmin(AssignationAcceptPacketStructure)}/>
              <Route path="/exam-tests/:et_id/assignations/:a_id/acceptPacketStructure/new" component={requireAdmin(AssignationAcceptPacketStructure)}/>
              <Route path="/exam-tests/:et_id/assignations/:a_id" component={requireAdmin(AssignationDetail)} />
              <Route path="/exam-tests/:id/assignations/" component={requireAdmin(Assignations)} />
              <Route path="/exam/:id/toAnswer" component={notRequireUser(ToAnswer)} />
              <Route path="/exams/new" component={requireAdmin(ExamNew)} />
              <Route path="/exams/:id/labelAnswers" component={requireAdmin(Downloads)} />
              <Route path="/exams/:id/apwindows/edit/:apwindow_id" component={requireAdmin(ApwindowForm)} />
              <Route path="/exams/:id/apwindows/new" component={requireAdmin(ApwindowForm)} />
              <Route path="/exams/:id/apwindows/attachment" component={requireAdmin(AddByAttachment)} />
              <Route path="/exams/:id/apwindows/:apwindow_id/students" component={requireAdmin(AddStudents)} />
              <Route path="/exams/:id/apwindows" component={requireAdmin(Apwindows)} />
              <Route path="/exams/:id/progress" component={requireAdmin(ExamProgress)} />
              <Route path="/exams/:id/notification" component={requireAdmin(Notification)} />
              <Route path="/exams/:id/exam-tests" component={requireAdmin(ExamTests)} />
              <Route path="/exams/:id/editParticipants" component={requireAdmin(ExamEditParticipants)} />
              <Route path="/exams/:id/edit" component={requireAdmin(ExamEdit)} />
              <Route path="/exams/:id/questionaries" component={requireAdmin(ExamQuestionaries)} />
              <Route path="/exams/:id/list" component={requireAdmin(ExamList)} />
              <Route path="/exams/:id" component={requireAdmin(Exam)} />
              <Route path="/exams" component={requireAdmin(Exams)} />
              <Route path="/faculties/:f_id/exam-tests/:et_id/facultyReport" component={requireAdmin(FacultyReport)} />
              <Route path="/faculties/:id/:semester" component={requireAdmin(FacultyExams)}/>
              <Route path="/faculties/:id/" component={requireAdmin(FacultySemesters)} />
              <Route path="/faculties" component={requireAdmin(Faculties)} />
              <Route path="/departments/:d_id/exam-tests/:et_id/departmentReport" component={requireAdmin(DepartmentReport)} />
              <Route path="/departments/:id/:semester" component={requireAdmin(DepartmentExams)}/>
              <Route path="/departments/:id/" component={requireAdmin(DepartmentSemesters)} />
              <Route path="/departments" component={requireAdmin(Departments)} />
              <Route path="/courses/:c_id/exam-tests/:et_id/courseReport" component={requireTeacher(CourseReport)} />
              <Route path="/courses/:c_id/super_matrix/:sm_id/courseReport" component={requireAdmin(CourseReport)} />
              <Route path="/courses/:id/historic" component={requireAdmin(CourseHistoricExams)} />
              <Route path="/courses/:c_id/semester/:semester/super_matrix/:sm_id/courseReport" component={requireTeacher(CourseReport)} />
              <Route path="/courses/:id/semester/:semester/historic" component={requireTeacher(CourseHistoricExams)} />
              <Route path="/courses/:id/:semester" component={requireTeacher(CourseExams)} />
              <Route path="/courses/:id/" component={requireTeacher(CourseSemesters)} />
              <Route path="/coursesAddTeachers" component={requireAdmin(CoursesAddTeachers)} />
              <Route path="/coursesAddStudents" component={requireAdmin(CoursesAddStudents)} />
              <Route path="/coursesTeacher" component={requireTeacher(CoursesTeacher)} />
              <Route path="/coursesAdmin" component={requireAdmin(CoursesAdmin)} />
              <Route path="/courses" component={requireAdmin(Courses)} />
              <Route path="/semesterYear" component={requireAdmin(SemesterAddYear)} />
              <Route path="/SemesterCreate" component={requireAdmin(SemesterCreate)} />
              <Route path="/themes/form/:id" component={requireAdmin(ThemeForm)} />
              <Route path="/themes/form" component={requireAdmin(ThemeForm)} />
              <Route path="/themes" component={requireAdmin(Themes)} />
              <Route path="/labels/form/:id" component={requireAdmin(LabelForm)} />
              <Route path="/labels/form" component={requireAdmin(LabelForm)} />
              <Route path="/labels" component={requireAdmin(Labels)} />
              <Route path="/texts/new" component={requireAdmin(TextNew)} />
              <Route path="/texts" component={requireAdmin(Texts)} />
              <Route path="/users/login" component={LogIn} />
              <Route path="/users/logout" component={requireUser(LogOut)} />
              <Route path="/users/new" component={requireAdmin(UserNew)} />
              <Route path="/users/:id/editRoles" component={requireAdmin(UserEditRoles)} />
              <Route path="/users" component={requireAdmin(Users)} />
              <Route path="/gradingMatrices/new" component={requireAdmin(GradingMatrixNew)} />
              <Route path="/gradingMatrices/:gm_id/userGradingMatrix/:u_id" component={requireAdmin(UserGradingMatrix)} />
              <Route path="/gradingMatrices/:id/edit" component={requireAdmin(GradingMatrixEdit)} />
              <Route path="/gradingMatrices/:id/editParticipants" component={requireAdmin(GradingMatrixEditParticipants)} />
              <Route path="/gradingMatrices/:id/editParams" component={requireAdmin(GradingMatrixEditParams)} />
              <Route path="/gradingMatrices/:id/levels/:tab" component={requireAdmin(GradingMatrixLevels)} />
              <Route path="/gradingMatrices/:id/editLevels" component={requireAdmin(GradingMatrixEditLevels)} />
              <Route path="/gradingMatrices/:id/associateSuperMatrix" component={requireAdmin(GradingMatrixAssociateSuperMatrix)} />
              <Route path="/gradingMatrices/:matrix_id/labels/form/:id" component={requireAdmin(LabelForm)} />
              <Route path="/gradingMatrices/:matrix_id/labels/form" component={requireAdmin(LabelForm)} />
              <Route path="/gradingMatrices/:id/labels" component={requireAdmin(Labels)} />
              <Route path="/gradingMatrices/:id" component={requireAdmin(GradingMatrix)} />
              <Route path="/gradingMatrices" component={requireAdmin(GradingMatrices)} />
              <Route path="/labeling" component={requireObserver(General)} />
              <Route path="/superMatrices/:m_id/students/:u_id" component={requireAdmin(StudentHistoricReport)} />
              <Route path="/superMatrices/:id/students" component={requireAdmin(SuperMatrixStudents)} />
              <Route path="/superMatrices/:id/edit" component={requireAdmin(SuperMatrixEdit)} />
              <Route path="/superMatrices/new" component={requireAdmin(SuperMatrixNew)} />
              <Route path="/superMatrices/:id" component={requireAdmin(SuperMatrix)} />
              <Route path="/superMatrices" component={requireAdmin(SuperMatrices)} />
              <Route path="/answerBank/:id" component={requireAdmin(AnswerFromBank)} />
              <Route path="/answerBank" component={requireAdmin(AnswerBank)} />
              <Route path="/AddAnswerBankMatricesList" component={requireAdmin(AddAnswerBankMatricesList)} />
              <Route path="/AddAnswerBank/:id" component={requireAdmin(AddAnswerBank)} />
              <Route path="/AddAnswerBankFromExcel" component={requireAdmin(AddAnswerBankFromExcel)} />
              <Route path="/mockup" component={notRequireUser(ReportMockups)} />
              <Route path="/examGrades/:id" component={notRequireUser(ExamGrades)} />
              <Route path="/students/:id" component={requireAdmin(StudentsInfo)} />
              <Route path="/students" component={requireAdmin(Students)} />
              {/* modulo videos */}
              <Route path="/usersVideos/new" component={requireObserverAdmin(UsersVideosNew)} />
              <Route path="/usersVideos" component={requireObserverAdmin(UsersVideos)} />
              <Route path="/videos/edit/:id" component={requireObserver(EditVideo)} />
              <Route path="/videos/new" component={requireObserverAdmin(NewVideoForm)} />
              <Route path="/videos/:id" component={requireObserver(ViewVideo)} />
              <Route path="/videos" component={requireObserverAdmin(VideoList)} />
              <Route path="/processes/permissions/:id" component={requireObserverAdmin(Permissions)} />
              <Route path="/processes/edit/:id" component={requireObserverAdmin(EditProcess)} />
              <Route path="/processes/view/:id" component={requireObserverAdmin(ViewProcess)} />
              <Route path="/processes/new" component={requireObserverAdmin(CreateProccess)} />
              <Route path="/processes" component={requireObserverAdmin(ProcessesList)} />
              <Route path="/observers/processes/view/:id" component={requireObserver(ViewProcess)} />
              <Route path="/observers/processes" component={requireObserver(ProcessesList)} />
              <Route path="/observers/videos/:id" component={requireObserver(ViewVideo)} />

              <Route path="/" component={Home} />{/*debe ir al final de todas las rutas o utilizar "exact" en caso contrario*/}
            </Switch>
          </div>
          <Version />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
