import axios from 'axios';
import { ROOT_URL, getAuthHeaders, isUnauthorizedError } from './constants';

// Constantes para el manejo de acciones sobre el estado de las ventanas de aplicacion.

// Actualización de las ventanas de aplicación en el estado
export const GET_APWINDOWS = 'GET_APWINDOWS';

// Actualización de una ventanas de aplicación en el estado
export const GET_APWINDOW = 'GET_APWINDOW';

// Actualización de los estudiantes de una ventana de aplicación en el estado
export const GET_APWINDOWS_STUDENTS = 'GET_APWINDOWS_STUDENTS';

// Limpieza del estado
export const CLEAN_STATE = 'CLEAN_STATE';

// Limpieza del estado
export const DELETE_APWINDOW = 'DELETE_APWINDOW';

/**
 * Obtiene todas las ventanas de aplicación de un examen en la aplicación.
 *
 * @export GET_APWINDOWS
 * @param pagination : Opciones de paginación.
 * @param {*} [successCallback=() => {}] : Función a llamar en caso de petición exitosa.
 * @param {*} [errorCallback=(error) => {}] : Función a llamar en caso de una petición con error.
 *                                           La función debe recibir por parámetro el error generado.
 * @returns Función de manejo de error o éxito de la petición.
 */
export function getApwindows(idExam, successCallback = () => {}, errorCallback = (error) => {}) {

  const request = axios.get(`${ROOT_URL}/apwindows/${idExam}/from_exam`, getAuthHeaders());

    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_APWINDOWS,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }


  export function getApwindow(apwindow_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/apwindows/${apwindow_id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_APWINDOW,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

  export function getApwindowsOfStudent(exam_id, student_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.get(`${ROOT_URL}/apwindows/${exam_id}/by_student?email=${student_id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: GET_APWINDOWS,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }

  export function deleteApwindow(apwindow_id, successCallback = () => {}, errorCallback = (error) => {}) {
    const request = axios.delete(`${ROOT_URL}/apwindows/${apwindow_id}`, getAuthHeaders());
  
    return (dispatch) => {
      request.then(({data}) => {
        dispatch({
          type: DELETE_APWINDOW,
          payload: data
        });
        successCallback();
      }).catch((error) => {
        if(!isUnauthorizedError(error, dispatch)) {
          errorCallback(error);
        }
      });
    }
  }
  