import React, {useState, useEffect} from "react";
import moment from "moment";
import ReactQuill from "react-quill";
import Acordion from "../../utils/Acordion";
import TestTextsList from "../../tests/TestTextsList";
import AnswerToTest from "./AnswerToTest";
import { useDispatch, useSelector } from "react-redux";
import { getExam } from "../../../actions/examsActions";
import { getApwindowsOfStudent } from "../../../actions/apwindowsActions";


export default function ToAnswer(props) {

  // constantes
  const examId = props.match.params.id;
  const emailUser = useSelector(state => state?.currentUser?.email)
  const exam = useSelector(state => state?.exams[examId])
  const apwindows = useSelector(state => state?.apwindows?.apwindows)
  const [loading, setLoading] = useState(null)
  const dispatch = useDispatch()

  const setExam = () => {
    setLoading(true)
    dispatch(getExam(examId,()=>{setLoading(false) }, ()=> {setLoading(false)}))
  }

  const getExamTestOption = (id) => {
    const index = exam?.examTestOptions?.findIndex(
      (examTestOption) => examTestOption.test_id === id
    )
    return exam?.examTestOptions[index]?.id;
  }

  const onTime = () => {
    if (exam) {
      const available = apwindows?.some(apwindow => (moment(apwindow.start_date) <= moment() &&  moment() <= moment(apwindow.deadline)))
      if (exam?.haveWindow && available) return true

      if (moment(exam.finalDate) >= moment() && moment() >= moment(exam.initialDate) && !exam?.haveWindow)
        return true
    }
    return false
  }

  //efectos
  useEffect(() => {
    if (exam && exam.haveWindow) {
      // console.log('llama las ventanas disponibles');
      dispatch(getApwindowsOfStudent(examId,emailUser, ()=> {}, ()=> {}))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[exam])

  useEffect(() => {
    setExam()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      {loading ? (
        <> cargando.... </>
      ) : (
        <>
          {onTime() ? (
            <>
              <h1>{exam?.title[0].toUpperCase() + exam?.title.substring(1)}</h1>
              {!exam.haveWindow ? (
                <h4>
                  Inicia: {moment(exam.initialDate).format('DD/MM/YY HH:mm')} - Termina: 
                  {moment(exam.finalDate).format('DD/MM/YY HH:mm')} 
                  (termina {moment(exam.finalDate).fromNow()})
                </h4>
              ) : (
                <>
                  <p>Estos son tus plazos:</p>
                  <ul>
                    {apwindows?.length > 0 ? apwindows?.map((apwindow, i) => {
                      const inicio = moment(apwindow.start_date).format('D [de] MMMM [de] YYYY, h:mm a')
                      const fin = moment(apwindow.deadline).format('D [de] MMMM [de] YYYY, h:mm a')
                      return (
                        <li key={`${apwindow.id}_${i}`}>
                          <p><b>Desde</b> el {inicio} <b>hasta</b> el {fin}</p>
                        </li>
                      )
                    }) : <li>no tienes plazos</li>}
                  </ul>
                  <br />
                </>
              )}
              <p>periodo: {exam.semester}</p> 
              <Acordion title="Instrucciones" open={true}>
                <ReactQuill
                  modules={{
                    toolbar: null
                  }}
                  readOnly ={true}
                  value={exam.instructions}
                />
              </Acordion>

              <h2>Preguntas</h2>
              {exam.examTestOptions.sort((a,b) => (a?.order||0) -(b?.order||0)).map((examTestOption) => {
                const test = exam.tests.find( test => test.id === examTestOption.test_id)
                return (
                <section key={`${test.title}#${test.id}`} style={ {border: "1px solid #aaa", borderRadius: "10px", marginTop: '1rem', padding: '3rem'}}>
                  <h2>{test.title}</h2>
                  <Acordion title="Informacion" open={true}>
                    <div className="container">
                      <div className="col-md-8">
                        <ReactQuill
                          modules={{
                            toolbar: null
                          }}
                          readOnly ={true}
                          value={test.instructions}
                        />
                      </div>
                      <div className="col-md-4">
                        <TestTextsList texts= {test.texts}/>
                      </div>
                    </div>
                  </Acordion>
                  <AnswerToTest testId={getExamTestOption(test.id)} examInfo={exam} setExamInfo={setExam} />
                </section>
              )})}
              <br></br>
              <div className="row">
                <div className="col-md-6 col-xs-6">
                  <button
                    className="btn btn-danger"
                    onClick={props.history.goBack}>
                    Volver
                  </button>
                </div>
                <div className="col-md-6 col-xs-6">
                </div>
              </div>
            </>
          ) : (
            <>
              <p>Al parecer no tienes acceso a esta pagina, consulta los permisos e intenta nuevamente</p>
            </>
          )}
        </>
      )}
    </>
  )
}