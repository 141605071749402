/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import '../../assets/stylesheets/StudentsInfo.css';

export default function Acordion({children = <></>, title = '', open = false, customHeader = <></>, noAfter = false}) {
  const hid = Math.random();
  const handleChange = (id) => {
    
    const answeId = id;

    const accordionHeader = document.getElementById(answeId);
    const accordionBody = accordionHeader.nextElementSibling;
    
    if (!accordionHeader.classList.contains('accordion__header--active')){
      expand(accordionBody);

    } else {
      contract(accordionBody);
    }

    accordionHeader.classList.toggle('accordion__header--active');

  }

  const expand = (panel) =>{
    panel.style.maxHeight = `calc(${panel.scrollHeight}px + 4rem)`;
    panel.style.padding = '1rem';
    panel.style.overflowX = 'scroll';
    if (panel.parentElement.closest('div.accordion__body') !== null) {
      let element = panel
      while (element.parentElement.closest('div.accordion__body') !== null) 
      {
        element = element.parentElement.closest('div.accordion__body')
        element.style.maxHeight = `calc(${panel.scrollHeight + element.scrollHeight}px + 4rem)`;
      } 
    }
  }

  const contract = (panel) => {
    panel.style.padding = '0';
    panel.style.maxHeight = null;
    panel.style.overflowX = null;

  }
  useEffect(()=>{open && handleChange(hid)},[])
  return (<>
    <div className="acordion">
      <div id={hid} className={`accordion__header ${noAfter && 'no_after'}`} onClick={(e) => handleChange(hid, e)} data-toggle={'tooltip'} title={'Ver calificaciones'}>
        <h4>{title || ''}</h4>
        {customHeader}
      </div>
      <div className='accordion__body' >
        {children}
      </div>
    </div>
  </>)
}